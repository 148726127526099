





































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import router from '@/router'
import { getCounter, setConter } from '@/api/index'
import moment from 'moment'
import momentTimezone from 'moment-timezone'
import { AppModule } from '@/store/modules/app'
import { clipboardSuccess } from '@/utils/clipboard'
import { currencyType } from '@/utils/constant'
import { setLanguage } from '@/utils/cookies'
import { testRules } from '@/utils/rules'
import { Form as ElForm } from 'element-ui'

@Component({
  name: 'qrIndex',
  components: {}
})
export default class extends Vue {
  get isMobile () {
    return window.screen.availWidth <= 475 && window.screen.availWidth >= 310
  }
  public clipboardSuccess = clipboardSuccess
  public loading: boolean = true
  public expireTime: string = ''
  public orderInfo: any = {
    amount: '',
    bank: [],
    is_expired: false,
    ordernumber: '',
    status: 0
  }
  public queryForm: any = {}
  public config: any = {}
  public configdata: any = {}
  public time: any = '00:00'
  public timer: any = null
  public poll: any = null
  public currencyType: any = currencyType
  public retryCount: number = 3
  public videoUrl: any = ''
  public qrcodeWatermark: any = ''
  public rules = testRules
  public chooseParam: any[] = []

  private flag: boolean = false
  // 按钮只触发一次
  private flagStr () {
    setTimeout(() => {
      this.flag = false
    }, 500)
  }

  public remove () {
    this.queryForm = {}
  }

  public save () {
    ;(this.$refs.queryForm as ElForm).validate(async (valid: boolean) => {
      if (valid) {
        try {
          this.loading = true
          this.flag = true
          const params = {
            ...this.queryForm
          }
          const data = {
            ex_data: JSON.stringify(params)
          }
          await setConter(data).then((res: any) => {
            this.loading = false
            this.flagStr()
            if (res.data.jump_url) {
              this.$message({
                message: 'Operation Successful!',
                type: 'success'
              })
              window.location.href = res.data.jump_url
            } else {
              this.$message({
                message: res.message,
                type: 'error'
              })
            }
          })
        } catch (e) {
          this.loading = false
          this.flagStr()
        }
      } else {
        return false
      }
    })
  }

  public async getCounter () {
    try {
      await getCounter().then((res: any) => {
        this.orderInfo = res
        if (
          this.orderInfo.choose_param &&
          this.orderInfo.choose_param.jump_url
        ) {
          window.location.href = this.orderInfo.choose_param.jump_url
          return
        }
        this.chooseParam = this.orderInfo.choose_param.from_params
        this.chooseParam.forEach((item: any) => {
          if (item.default) {
            this.queryForm[item.field] = item.default
          }
        })
        if (!AppModule.chatInfo) {
          this.config = res.extra_param.config
          this.configdata = res.extra_param

          AppModule.SetChatInfo(res.extra_param.config)
          // AppModule.SetChatInfo(res.extra_param)
        }
        // 取最小时间
        const time = res.extra_param.expire_time
          ? res.extra_param.expire_time > res.expire_time
            ? res.expire_time
            : res.extra_param.expire_time
          : res.expire_time
        // 根据语言获取时区
        const timezone =
          this.$route.query['language'] === 'vi' ||
          this.$route.query['language'] === 'th' ||
          this.$route.query['language'] === 'id'
            ? 'Asia/Bangkok'
            : ''
        this.expireTime = timezone
          ? momentTimezone
              .tz(time * 1000, timezone)
              .format('YYYY-MM-DD HH:mm:ss')
          : moment(time * 1000).format('YYYY-MM-DD HH:mm:ss')
        if (!res.is_expired && res.status === 0) {
          // 订单未过期 且 未支付
          if (!this.poll) {
            // 轮询未开始，则开始轮询
            this.poll = window.setInterval(() => {
              this.getCounter()
            }, 5000)
            const diffTime = res.current_time * 1000 - Date.now()
            this.timer = window.setInterval(async () => {
              let start = momentTimezone.tz(Date.now() + diffTime, timezone) //获取开始时间
              let end = momentTimezone.tz(time * 1000, timezone) //结束时间
              let diff = end.diff(start) //时间差
              let minutes: any = moment.duration(diff).minutes()
              minutes = minutes < 10 ? '0' + minutes : minutes
              let seconds: any = moment.duration(diff).seconds()
              seconds = seconds < 10 ? '0' + seconds : seconds
              if (diff > 0) {
                this.time = minutes + ':' + seconds
              } else {
                window.clearInterval(this.timer)
                window.clearInterval(this.poll)
                router.replace({ name: 'errEn' })
              }
            }, 1000)
          }
        } else if (res.is_expired) {
          // 订单已过期
          if (this.poll) {
            // 轮询开启则关闭
            window.clearInterval(this.poll)
            window.clearInterval(this.timer)
          }
          router.replace({ name: 'errEn' })
        } else if (res.status === 1) {
          // 已支付
          if (this.poll) {
            // 轮询开启则关闭
            window.clearInterval(this.poll)
            window.clearInterval(this.timer)
          }
          router.replace({ name: 'successEn' })
        }
        this.loading = false
      })
    } catch (e) {
      console.log('接口报错')
      this.loading = false
      // if (!this.poll) {
      //   if (this.retryCount-- > 0) {
      //     this.getCounter()
      //   } else {
      //     this.$confirm(`Do you want to try again?`, 'The Request Timeout')
      //       .then(() => {
      //         this.retryCount = 3
      //         this.getCounter()
      //       })
      //       .catch(() => {
      //         router.replace({ name: 'errEn' })
      //       })
      //   }
      // }
      // throw new Error(`${this.$route.path}Counter接口出错`)
    }
  }
  public async oneResponse () {
    try {
      this.loading = true
      await getCounter().then((res: any) => {
        this.chooseParam = res.choose_param.from_params
        // this.chooseParam.map((item: any) => {
        //   this.queryForm[item.field] = item.default
        //   let a = item.field //字段
        //   let b = item.default //默认参数
        //   console.log(item.field)
        //   console.log(item.default)
        // })
        this.loading = false
      })
    } catch (e) {
      this.loading = false
    }
  }

  public async init () {
    await this.getCounter()
    await this.oneResponse()
  }

  mounted () {
    this.init()
    setLanguage('en')
  }

  destroyed () {
    if (this.poll || this.timer) {
      window.clearInterval(this.poll)
      window.clearInterval(this.timer)
    }
  }
}
